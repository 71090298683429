import React from "react"
import Layout from "../components/layout/layout"
import { Row, Col, Image } from 'react-bootstrap';
import { Person } from 'react-bootstrap-icons';
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from
  "mdbreact";
var ex_news = 0
export default function Home(props) {
  return (
    <Layout>
      <MDBContainer
        className="p-0"
      >
        <MDBCarousel
          activeItem={1}
          length={4}
          showControls={true}
          showIndicators={true}
          className="z-depth-1 p-0"
        >
          <MDBCarouselInner>

            <MDBCarouselItem itemId="1">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="/img/GruppbildClasp.jpg"
                  alt="First slide"
                />
                <MDBMask overlay="black-light" />
              </MDBView>
              <MDBCarouselCaption>
                <h3 className="h3-responsive">Welcome to CLASP</h3>
              </MDBCarouselCaption>
            </MDBCarouselItem>

            <MDBCarouselItem itemId="2">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="/img/reinact.jpeg"
                  alt="Second slide"
                />
                <MDBMask overlay="black-slight" />
              </MDBView>
              <MDBCarouselCaption>
                <h3 className="h3-responsive">Reasoning and Interaction Conference</h3>
                <a href="https://sites.google.com/view/reinact2021/home">ReInAct 2021</a>
              </MDBCarouselCaption>
            </MDBCarouselItem>

            <MDBCarouselItem itemId="3">
              <MDBView>
                <img
                  className="img-fluid d-block w-100"
                  src="/img/unnamed.jpg"
                  alt="Third slide"
                />
                <MDBMask overlay="black-strong" />
              </MDBView>
              <MDBCarouselCaption>
                <h3 className="h3-responsive">Probability and Meaning Conference</h3>
                <a href="https://sites.google.com/view/pam2020/home">PaM2020</a>
              </MDBCarouselCaption>
            </MDBCarouselItem>

            <MDBCarouselItem itemId="4">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="/img/unnamed -2.jpg"
                  alt="Forth slide"
                />
                <MDBMask overlay="black-slight" />
              </MDBView>
              <MDBCarouselCaption>
                <h3 className="h3-responsive">13th International Conference on Computational Semantics</h3>
                <a href="https://sites.google.com/view/iwcs2019/home">IWCS2019</a>
              </MDBCarouselCaption>
            </MDBCarouselItem>
          </MDBCarouselInner>
        </MDBCarousel>
      </MDBContainer>
      <Row>
        <Col
          className="main-page-col-margin"
        >
          <p>
	  The Centre for Linguistic Theory and Studies in Probability (CLASP) focuses its research on the application of probabilistic and information theoretic methods to the analysis of natural language.
	  CLASP is concerned both with understanding the cognitive foundations of language and developing efficient language technology. We work at the interface of computational linguistics/natural language processing, theoretical linguistics, and cognitive science.
	  </p>
	  <p>
	  CLASP is located in Gothenburg, Sweden at the University of Gothenburg. We are part of the Department of Philosophy, Linguistics and Theory of Science (<a href="https://www.gu.se/en/flov">FLoV</a>).
	  </p>
        </Col>
      </Row>
      {props.data.latest_news.totalCount > 0 &&
        <div>
          {props.data.latest_news.news.map((entry, index) => {
            const news_entry = entry.news_entry
            if(news_entry.frontmatter.expired == false && new Date(news_entry.frontmatter.date) > new Date()){
              ex_news = ex_news + 1 
            }
          })}
          {ex_news > 0 &&
            <Row>
              <Col
                className="main-page-col-margin"
              >
                <h1 className="text-center">News</h1>
                <hr />
              </Col>
            </Row>
          }
          {props.data.latest_news.news.map((entry, index) => {
            // const entry.news_entry = entry.entry.news_entry
            if(entry.news_entry.frontmatter.expired == false && formatDate(entry.news_entry.frontmatter.date) >= formatDate(new Date())){ 
              if (entry.news_entry.frontmatter.type == "conference"){
              return(
                <Row className="news-entry">
                  <Col
                    className="feature-item ">
                        <h5 className="p-0 ml-0"> Conference</h5>
                        <p className="p-0 ml-0"> On: {entry.news_entry.frontmatter.date}</p>
                        <a href={entry.news_entry.fields.slug}>{entry.news_entry.frontmatter.title}</a>
                      <hr />
                      </Col>
                    </Row>
                      )
                    }
              if (entry.news_entry.frontmatter.type == "defence"){
                return(
                  <Row className="news-entry">
                    <Col
                      className="feature-item ">
                          <h5 className="p-0 ml-0"> PhD defence</h5>
                          <p className="p-0 ml-0"> On: {entry.news_entry.frontmatter.date}</p>
                          <p className="p-0 ml-0"> Presented by: {entry.news_entry.frontmatter.presented_by}</p>
                          <a href={entry.news_entry.fields.slug}>{entry.news_entry.frontmatter.title}</a>
                        <hr />
                        </Col>
                      </Row>
                        )
                      }
                else{
                  return(
                      <Row className="news-entry">
                        <Col
                        className="feature-item ">
                        <h5 className="p-0 ml-0"> Research Seminar</h5>
                        <p className="p-0 ml-0"> On: {entry.news_entry.frontmatter.date}</p>
                        <p className="p-0 ml-0"> Presented by: {entry.news_entry.frontmatter.presented_by}</p>
                        <a href={entry.news_entry.fields.slug}>{entry.news_entry.frontmatter.title}</a>
                      <hr />
                      </Col>
                </Row>
                      )
                }
            }
          })}
        </div>
      }

      <hr /><Row>
        <Col
          className="main-page-col-margin"
        >
          <h1 className="text-center">
          Direction
        </h1>
          <hr />
        </Col>
      </Row>
      <Row>

        {props.data.directors.totalCount > 0 &&
          <Col
            className="feature-item col-12 col-md-4 ">
            <p className="p-0 ml-0">Chief Scientist</p>
            <a href={props.data.directors.people[2].person.fields.slug}>
              {props.data.directors.people[2].person.frontmatter.name}
            </a>
            <hr />
            {props.data.directors.people[2].person.frontmatter.profileImage ? <Image className="rounded mx-auto d-block" src={props.data.directors.people[2].person.frontmatter.profileImage.publicURL} roundedCircle /> : <Person style={{ width: "100%", height: "auto" }} />}
            <hr />
            <p>
              {props.data.directors.people[2].person.excerpt}
            </p>
          </Col>
        }
        {props.data.associate_directors.totalCount > 0 &&
          <Col
            className="feature-item col-12 col-md-4"
          >
            <p className="p-0 ml-0">Coordinating Director</p>
            <a href={props.data.associate_directors.people[0].person.fields.slug}>
              {props.data.associate_directors.people[0].person.frontmatter.name}
            </a>
            <hr />
            {props.data.associate_directors.people[0].person.frontmatter.profileImage ? <Image className="rounded mx-auto d-block" src={props.data.associate_directors.people[0].person.frontmatter.profileImage.publicURL} roundedCircle /> : <Person style={{ width: "100%", height: "auto" }} />}
            <hr />
            <p>
              {props.data.associate_directors.people[0].person.excerpt}
            </p>
          </Col>
        }
        {props.data.administrators.totalCount > 0 &&
          <Col
            className="feature-item col-12 col-md-4"
          >
            <p className="p-0 ml-0">Administrator</p>
            <a href={props.data.administrators.people[0].person.fields.slug}>
              {props.data.administrators.people[0].person.frontmatter.name}
            </a>
            <hr />
            {props.data.administrators.people[0].person.frontmatter.profileImage ? <Image className="rounded mx-auto d-block" src={props.data.administrators.people[0].person.frontmatter.profileImage.publicURL} roundedCircle /> : <Person style={{ width: "100%", height: "auto" }} />}
            <hr />
            <p>
              {props.data.administrators.people[0].person.excerpt}
            </p>
          </Col>
        }
      </Row>
      <hr />
      <Row>
        <Col sm={2}>
          <p> Funded By</p>
          <img src="img/1278654_vr_logo.png"></img>
        </Col>
	  <Col sm={10}>
	  <p>CLASP is funded by a 10 year grant from the Swedish Research Council (2015-2025). The funding from the Swedish Research Council and GU grant allows recruitment of prof. Shalom Lappin, a senior lecturer, a researcher/project coordinator, 12 PhD and 9 postdoctoral research positions. The funding also provides means for workshops, conferences and visits of guest researchers to Gothenburg. CLASP will bring together researchers in theoretical linguistics, computational linguistics, logic, AI, cognitive psychology, and robotics.</p>
        </Col>
      </Row>
    </Layout>
  )
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export const query = graphql`
  {
    directors: allMarkdownRemark(filter: {fields: {slug: {regex: "/^/people//"}}, frontmatter: {role: {eq: 0}}}) {
      people: edges {
        person: node {
          fields {
            slug
          }
          frontmatter {
            title
            name
            profileImage {
              publicURL
            }
          }
          excerpt
        }
      }
      totalCount
    }
    associate_directors: allMarkdownRemark(filter: {fields: {slug: {regex: "/^/people//"}}, frontmatter: {role: {eq: 1}}}) {
      people: edges {
        person: node {
          fields {
            slug
          }
          frontmatter {
            title
            name
            profileImage {
              publicURL
            }
          }
          excerpt
        }
      }
      totalCount
    }
    administrators: allMarkdownRemark(filter: {fields: {slug: {regex: "/^/people//"}}, frontmatter: {role: {eq: 2}}}) {
      people: edges {
        person: node {
          fields {
            slug
          }
          frontmatter {
            title
            name
            profileImage {
              publicURL
            }
          }
          excerpt
        }
      }
      totalCount
    }
  latest_news: allMarkdownRemark(filter: {fields: {slug: {regex: "/^/news//"}}, frontmatter: {expired: {in: false}, date: {}}}, sort: {fields: frontmatter___date, order: ASC}, limit: 5) {
    news: edges {
      news_entry: node {
        fields {
          slug
        }
        frontmatter {
          presented_by
          title
          type
          date(formatString: "MMMM DD, YYYY")
          expired
          bannerImage {
            publicURL
          }
        }
        excerpt
      }
    }
    totalCount
  }
  }
`
